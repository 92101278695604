import React, { Component } from 'react'
import {PhotoAndText} from './PhotoAndText'
import backgroundTest from '../../assets/backgroundTest.jpg'
import background2 from '../../assets/background2.jpg'
import { VimeoView } from './VimeoView'

// export const Home = () => {
  
//   return (
//   <>
//     <PhotoAndText image={backgroundTest} heading='Byeah' subheading='We Make Apps'/>
//     <PhotoAndText image={background2} heading='We are musicians and educators'/>
//   </>
//   )

//   }


export default class Home extends Component {
  componentDidMount() {
    window.addEventListener('scroll', this.listenToScroll)
  }
  
  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll)
  }
  
  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop
  
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight
  
    const scrolled = winScroll / height
  
    this.setState({
      theposition: scrolled,
    })
  }
  render() {
  return (
  <>
    <VimeoView vimeoID='342190245'/>
    <PhotoAndText image={backgroundTest} heading='Byeah' subheading='We Make Apps'/>
    <PhotoAndText image={background2} heading='We are musicians and educators'/>
  </>
  )
  }
}
