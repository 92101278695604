import React from 'react'
import styled from 'styled-components'


const OuterWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
`

const Wrapper = styled.div`
    width: 100%;
    height: 100%;

`


const IFrame = styled.iframe`

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 0;

    /* box-sizing: border-box; */

    border: 0;
    min-width: 5000px;
    min-height: 100%;




`

// const Wrapper = styled.div`
// background-color: yellow;
// overflow: hidden;
// display: flex;
// justify-content: center;
// flex-direction: column;

// position: relative;
// overflow: hidden;
// padding-top: 56.25%;
// `


// const IFrame = styled.iframe`
//     background-color: black;
//     border: none;
//     position: absolute;
//     border: 0;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;

// `

export const VimeoView = ({vimeoID}) => (


        <OuterWrapper>
        {/* <Wrapper> */}
            <IFrame 
            data-domain-dependent="https://player.vimeo.com/api/player.js" 
            data-src={`https://player.vimeo.com/video/${vimeoID}?autoplay=1&loop=1&autopause=0&background=1`}
            // width={640}
            // height={348}
            frameborder="0" 
            webkitallowfullscreen="" 
            mozallowfullscreen="" 
            allowfullscreen="" 
            allow= 'autoplay'
            src={`https://player.vimeo.com/video/${vimeoID}?autoplay=1&loop=1&autopause=0&background=1`}
            >     

            </IFrame>
            

        {/* </Wrapper> */}
        </OuterWrapper>
)