import React from 'react';
import { Routes } from './Routes'
import { CustomNavbar } from './CustomNavbar'

export const App = () =>  (
      <>
        {/* <CustomNavbar /> */}
        <Routes />
      </>
    );
