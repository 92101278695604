import React from 'react'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"

import Home from './Pages/HomePage/Home'
import {About} from './Pages/AboutPage/About'
import { Contact } from './Pages/ContactPage/Contact'
import { NoMatch } from './Pages/NoMatchPage/NoMatch'
import { Json } from './components/Json'


export const Routes = () => {
    return(
        <Router>
        <Switch>
          <Route exact path="/" component= {Home} />
          <Route path="/about" component= {About} />
          <Route path="/contact" component= {Contact} />
          <Route path="/JSON" component= {Json} />
          <Route component= {NoMatch} />
        </Switch>
      </Router>
    )
}
